<template>
  <div class="space-y-12 md:space-y-24">
    <HeaderHome
      :title="page.title"
      :sub-title="page.template?.sub_title"
      :video="page.template?.video"
      :images="page.images"
      :next-event="upcomingEvent"
      :background-color="page.template?.background_color"
    />
    <FullWidthText
      v-if="page.template?.content_title"
      :color="page.template?.title_color"
      :text="page.template?.content_title"
    />
    <section class="container">
      <UiCard
        variation="green"
        class="space-y-16"
      >
        <div
          class="flex flex-col items-center gap-10 rounded-none sm:rounded-3xl md:flex-row"
        >
          <EventCard
            v-if="page.template?.highlight_event"
            class="basis-1/2"
            :event="page.template?.highlight_event"
          />
          <div class="flex basis-1/2 flex-col gap-10">
            <h2>Evenementen</h2>
            <SofieText
              v-if="page.template?.event_text"
              :value="page.template?.event_text"
            />
            <UiLink
              variation="white"
              :to="{name: 'event'}"
              class="w-fit px-4 py-6"
            >
              Bekijk alle evenementen
            </UiLink>
          </div>
        </div>
        <EventHighlighted :events="page.template?.highlighted_events" />
      </UiCard>
    </section>

    <section
      id="aanmelden-nieuwsbrief"
      class="container"
    >
      <UiCTA
        title="Blijf op de hoogte"
        :content="page.template?.newsletter_content"
        :image-src="page.template?.newsletter_image?.url"
      >
        <template #action>
          <FormKit
            v-if="!sent"
            type="form"
            :actions="false"
            @submit="submitNewsletter"
          >
            <FormKit
              type="text"
              name="company"
              placeholder="Bedrijfsnaam"
              validation="required"
              validation-name="Bedrijfsnaam"
            />
            <FormKit
              type="email"
              name="email"
              placeholder="Jouw e-mailadres..."
              validation="required"
              validation-name="E-mailadres"
            />
            <FormKit
              type="submit"
              input-class="!w-fit"
              label="Ja, ik wil op de hoogte blijven"
            />
          </FormKit>
          <p
            v-else
            class="rounded-full bg-primary p-4 text-center"
          >
            Je bent succesvol aangemeld voor de nieuwsbrief van Foodyard!
          </p>
        </template>
      </UiCTA>
    </section>
  </div>
</template>

<script setup lang="ts">
import {useNewsletterSignupMutation, useUpcomingEventQuery} from '~/graphql/graphql';
import {notifyGraphQLError} from '~/composables/vue3-notification';

definePageMeta({
  middleware: 'sofie-page',
});

useSeoMeta({
  title: 'Foodyard',
});

const page = useSofiePage();

const {result} = useUpcomingEventQuery();
const upcomingEvent = computed(() => result.value?.upcomingEvent);

const sent = ref(false);

const {mutate: submitNewsletter, onDone: newsletterSubmitted, onError} = useNewsletterSignupMutation();

onError(notifyGraphQLError);

newsletterSubmitted(() => {
  sent.value = true;
});
</script>
